import domReady from '@wordpress/dom-ready';

domReady(() => {
  const sticky = 60;
  let timer = null;
  const progressBar = document.querySelector('#progress-bar');

  function stickyNavbar() {
    const st = window.scrollY;
    if (st >= sticky) {
      document.body.classList.add('sticky-navbar');
    } else {
      document.body.classList.remove('sticky-navbar');
    }
  }

  function updateProgressBar() {
    const docElem = document.documentElement;
    const docBody = document.body;
    const scrollTop = docBody.scrollTop || docElem.scrollTop;
    const height = docElem.scrollHeight - docElem.clientHeight;
    const readPercent = (scrollTop / height) * 100;

    if (readPercent > 0) {
      progressBar.style.setProperty('--progress', readPercent + '%');
    } else {
      progressBar.style.setProperty('--progress', '0%');
    }
  }

  window.addEventListener('scroll', () => {
    updateProgressBar();

    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      stickyNavbar();
    }, 40);
  });

  stickyNavbar();
  updateProgressBar();
});
